@import "compass";
@import "theme_variables";
.header_user_info {
	float: left;
	// border-left: 1px solid #515151;
	// border-right: 1px solid #515151;
	a {
		color: $light-text-color;
		font-weight: bold;
		display: block;
		padding: 14px 9px 14px 8px;
		cursor: pointer;
        line-height:28px;
		@media (max-width: $screen-xs - 1) {
			font-size: 11px;
		}
		@media (max-width: $screen-sm) {
            padding: 2px 9px 2px 8px;
		}
		&:hover, &.active {
			background: black;
		}
	}
}
